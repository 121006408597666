import { RouteRecordRaw } from 'vue-router';
import { useModulesStore } from 'src/stores/modulesStore';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: () => {
      const modulesStore = useModulesStore();
      const activeModules = modulesStore.modules;
      
      const availableRoutes = ['restocking', 'inventory'];
      const firstAvailable = availableRoutes.find(route => activeModules.includes(route));
      
      return { name: firstAvailable || 'login' };
    },
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '/restocking', component: () => import('pages/ProductsPage.vue'), name: 'restocking' },
      { path: '/inventory', component: () => import('pages/InventoryPage.vue'), name: 'inventory' },
      { path: '/module-not-found', component: () => import('pages/ModuleNotFound.vue'), name: 'module-not-found' },
      { path: '/login', component: () => import('pages/LoginPage.vue'), name: 'login' }
    ],
  },

  // Always leave this as last one
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue'),
  },
];

export default routes;
