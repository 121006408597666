import { Cookies } from 'quasar';
import { jwtDecode } from 'jwt-decode';
import { FOToken } from 'src/interfaces/FOAPI/FOToken';

const tokenKey = 'FWH-TOKEN';
const expirationDate = new Date().getDate() + 14;

function setToken(token: string) {
  Cookies.set(tokenKey, token, {
    expires: expirationDate,
  });
}

function getToken(): string | null {
  return Cookies.get(tokenKey);
}

function removeToken() {
  Cookies.remove(tokenKey);
}

function isTokenValid(): boolean {
  const token = getToken();
  if (!token) return false;

  try {
    const decoded: { exp: number } = jwtDecode(token);
    const now = Math.floor(Date.now() / 1000);
    return decoded.exp > now;
  } catch (e) {
    return false;
  }
}

function getCustomerId(): string {
  const token = getToken();
  if (!token) return '';
  
  try {
    const decoded: { customerId: string } = jwtDecode(token);
    return decoded.customerId;
  } catch (e) {
    return '';
  }
}

function getClientId(): string {
  const token = getToken();
  if (!token) return '';
  
  try {
    const decoded: { foApiToken: string } = jwtDecode(token);
    const foApiToken = decoded.foApiToken;
    const foApiTokenDecoded: FOToken = jwtDecode(foApiToken);
    return foApiTokenDecoded.mandant;
  } catch (e) {
    return '';
  }
}

function getOfficeId(): string {
  const token = getToken();
  if (!token) return '';
  
  try {
    const decoded: { foApiToken: string } = jwtDecode(token);
    const foApiToken = decoded.foApiToken;
    const foApiTokenDecoded: FOToken = jwtDecode(foApiToken);
    return foApiTokenDecoded.geschaeftsstelle;
  } catch (e) {
    return '';
  }
}

export {
  setToken,
  getToken,
  removeToken,
  isTokenValid,
  getCustomerId,
  getClientId,
  getOfficeId,
};
