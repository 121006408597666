import { defineStore } from 'pinia';
import { Cookies } from 'quasar';

export const useModulesStore = defineStore('modules', {
  state: () => ({
    modules: Cookies.get('modules') ? Cookies.get('modules') : '',
  }),
  actions: {
    setModules(modules: string) {
      this.modules = modules;
    },
    getModules() {
      return this.modules;
    }
  },
});